import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import axios from 'axios'

const URL = process.env.REACT_APP_API_BASE_URL
const API_KEY = process.env.API_KEY

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isValidated: false,
      isSending: false,
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleButtonClick = e => {
    if (this.state.isSending) {
      return false
    }
    this.setState({ isSending: true })
    const url = URL + '/api/inquiry'
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-api-key': API_KEY,
      },
      data: { ...this.state },
      url,
    }
    axios(options)
      .then(() =>
        navigate('/contact/thanks', {
          state: { ...this.state },
        })
      )
      .catch(error => {
        this.setState({ isSending: false })
        alert(error.response)
      })
  }

  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>お問い合わせ</h1>
              <div className="field">
                <label className="label" htmlFor={'customerName'}>
                  お名前
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={'text'}
                    name={'name'}
                    onChange={this.handleChange}
                    id={'name'}
                    required={true}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor={'email'}>
                  メールアドレス
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={'email'}
                    name={'email'}
                    onChange={this.handleChange}
                    id={'email'}
                    required={true}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor={'message'}>
                  お問い合わせ内容
                </label>
                <div className="control">
                  <textarea
                    className="textarea"
                    name={'message'}
                    onChange={this.handleChange}
                    id={'message'}
                    required={true}
                  />
                </div>
              </div>
              <div className="field">
                <button
                  className="button is-link"
                  onClick={() => this.handleButtonClick()}
                >
                  {this.state.isSending ? '送信中...' : '送信'}
                </button>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
